.sendFileForPrice-wrapper{
    width: 100%;
    margin-top: 50px;
    max-width: 460px;
    position: relative;
}
.sendFileForPrice-form{
    position: relative;
    width: 100%;
}
.sendFileForPrice-form::after{
    content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background:rgb(255, 255, 255) url("../images/loading.gif") center/100px no-repeat;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease 0s;
}
.sendFileForPrice-form._sendingFile::after{
    opacity: 1;
 visibility: visible;
}
.sendFileForPrice-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.sendFileForPrice-telInput{
    height: 60px;
  width: 45%;
  display: flex;
  
  align-items: center;
  padding: 0 15px;
  border: 1px solid #ff7a22;
  border-radius: 15px;
  font-family: 'Montserrat';
  
}
.sendFileForPrice-submitButton{
    width: 35%;
  height: 60px;
  background: #ff7a22;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: none;
  font-family: 'Montserrat';
  font-weight: bold;
}
.sendFileForPrice-submitButton:hover{
    transition: 0.6s ;
    -webkit-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
-moz-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
}

.sendFileForPrice-submitButton:active{
    background-color:#cc621b;
}
.sendFileForPrice-submitButton:disabled{
    background: #ff7a2262;
}
.sendFileForPrice-alert{
    margin-top: 10px;
}