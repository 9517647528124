.doublePolished-wrapper{
    height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 150px;
}
.doublePolished-container{
    height: 100%;
    width: 80%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.doublePolished-container1{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.doublePolished-container2{
    height: 60%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.doublePolished-image{
    width: 90%;
}
.doublePolished-title{
    color:#ff7a22;
}
@media (max-width: 1224px) {
    .doublePolished-wrapper{
        height: auto;
        margin-top: 200px;
    }
    .doublePolished-container{
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .doublePolished-container1{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20px;
    }
    .doublePolished-container2{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 20px;
    }
  }
  @media (max-width: 770px) {
    .doublePolished-wrapper{
        height: auto;
        margin-top: 300px;
    }
  }
  @media (max-width: 460px) {
    .doublePolished-wrapper{
        height: auto;
        margin-top: 700px;
    }
    .doublePolished-container1{
        margin-bottom: 30px;
    }
  }
  @media (max-width: 321px) {
    .doublePolished-wrapper{
        height: auto;
        margin-top: 900px;
    }
  }