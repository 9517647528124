.orderingPage-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.orderingPage-container {
  width: 80%;
  position: relative;
}
.orderingPage-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.orderingPage-goBackButton {
  background-color: transparent;
  border: none;
}
.orderingPage-goBackButton:hover {
  color: #ff7a22;
}
.orderingPage-tabs {
  width: 100%;
}
.orderingPage-tabPanelWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.orderingPage-tabPanel {
  width: 50%;
  margin-bottom: 200px;
  position: relative;
}
.orderingPage-glass-shapeSelect {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .orderingPage-glass-shapeSelect-itemsWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .orderingPage-glass-shapeSelect-item {
      width: 49%;
      height: 200px;
      padding: 5%;
      box-sizing: border-box;
      border-radius: 25px;
      background-color: rgb(241, 241, 241);

      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .orderingPage-glass-shapeSelect-item-h4 {
      margin: 0;
    }
    .orderingPage-glass-shapeSelect-item:hover {
      background-color: #ff7a22;
      color: white;
      -webkit-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
    }
    .orderingPage-glass-shapeSelect-item:active {
      background-color: #d45a09;
    }
    .orderingPage-glass-shapeSelect-item-selected {
      width: 49%;
      height: 200px;
      padding: 5%;
      box-sizing: border-box;
      border-radius: 25px;
      background-color: #ff7a22;
      color: white;
      -webkit-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
.orderingPage-glass-widthHeight {
  width: 100%;
  height: 200px;
  background-color: rgb(241, 241, 241);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
  box-sizing: border-box;
  margin-top: 20px;
  .orderingPage-glass-widthHeight-inputsWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .orderingPage-glass-widthHeight-input {
      width: 35%;
      height: 50px;
      border: 1px solid #ff7a22;
      border-radius: 15px;
      font-family: "Montserrat";
      text-align: center;
    }
  }
}
.orderingPage-glass-formFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  .orderingPage-glass-holes {
    width: 49%;
    height: 200px;
    background-color: rgb(241, 241, 241);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5%;
    box-sizing: border-box;
    .orderingPage-glass-holes-inputsWrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .orderingPage-glass-holes-input-label {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: small;
        .orderingPage-glass-holes-input {
          width: 35%;
          height: 30px;
          border: 1px solid #ff7a22;
          border-radius: 10px;
          font-family: "Montserrat";
          font-size: medium;
          text-align: center;
          margin-top: 10px;
        }
      }
      .orderingPage-glass-holes-input-label-disabled {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: small;
        color: #616161;
        .orderingPage-glass-holes-input-disabled {
          width: 35%;
          height: 30px;
          border: 1px solid #616161;
          border-radius: 10px;
          font-family: "Montserrat";
          font-size: medium;
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }
  .orderingPage-glass-amount {
    width: 49%;
    height: 200px;
    background-color: rgb(241, 241, 241);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .orderingPage-glass-amount-input-label {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .orderingPage-glass-amount-input {
        width: 35%;
        height: 30px;
        border: 1px solid #ff7a22;
        border-radius: 10px;
        font-family: "Montserrat";
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}
.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox + label::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox:checked + label::before {
  border-color: #ff7a22;
  background-color: #ff7a22;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
/* стили при наведении курсора на checkbox */
.custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
  border-color: #ff7a22;
}
/* стили для активного состояния чекбокса (при нажатии на него) */
.custom-checkbox:not(:disabled):active + label::before {
  background-color: #ffedb3;
  border-color: #f1ffb3;
}
/* стили для чекбокса, находящегося в фокусе */
.custom-checkbox:focus + label::before {
  box-shadow: 0 0 0 0.2rem rgba(245, 147, 20, 0.486);
}
/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox:focus:not(:checked) + label::before {
  border-color: #ffcc80;
}
/* стили для чекбокса, находящегося в состоянии disabled */
.custom-checkbox:disabled + label::before {
  background-color: #e9ecef;
}
.orderingPage-glass-nextButton {
  width: 100%;
  height: 60px;
  margin-top: 20px;
  background-color: #ff7a22;
  border: none;
  color: white;
  border-radius: 30px;
  font-family: "Montserrat";
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.orderingPage-glass-nextButton:hover {
  background-color: #f57017;
  -webkit-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
}
.orderingPage-glass-nextButton-disabled {
  width: 100%;
  height: 60px;
  margin-top: 20px;
  background-color: rgb(196, 195, 195);
  border: none;
  color: white;
  border-radius: 30px;
  font-family: "Montserrat";
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.orderingPage-personalData-nextButton{
  width: 49%;
  height: 60px;
  margin-top: 20px;
  background-color: #ff7a22;
  border: none;
  color: white;
  border-radius: 30px;
  font-family: "Montserrat";
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.orderingPage-personalData-nextButton:hover {
  background-color: #f57017;
  -webkit-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
}
.orderingPage-personalData-nextButton-disabled {
  width: 49%;
  height: 60px;
  margin-top: 20px;
  background-color: rgb(196, 195, 195);
  border: none;
  color: white;
  border-radius: 30px;
  font-family: "Montserrat";
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.orderingPage-personalData-backButton{
  width: 49%;
  height: 60px;
  margin-top: 20px;
  background-color: #ff7a22;
  border: none;
  color: white;
  border-radius: 30px;
  font-family: "Montserrat";
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.orderingPage-personalData-backButton:hover {
  background-color: #f57017;
  -webkit-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
}
.orderingPage-personalData-buttons-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.orderingPage-personalData-name{
  width: 100%;
  height: 200px;
  background-color: rgb(241, 241, 241);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
  box-sizing: border-box;
  margin-top: 20px;
}
.orderingPage-personalData-input-label{
  margin-bottom: 20px;
}
.orderingPage-personalData-input{
  height: 50px;
  border:1px solid #ff7a22;
  border-radius: 15px;
  font-family: 'Montserrat';
  font-size: 15px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
}
.orderingPage-personalData-contacts{
  width: 100%;
  height: 250px;
  background-color: rgb(241, 241, 241);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
  box-sizing: border-box;
  margin-top: 20px;
}
/* для элемента input c type="radio" */
.custom-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
/* для элемента label связанного с .custom-radio */
.custom-radio+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
/* создание в label псевдоэлемента  before со следующими стилями */
.custom-radio+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #ee9644;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
/* стили при наведении курсора на радио */
.custom-radio:not(:disabled):not(:checked)+label:hover::before {
  border-color: #ffa927;
  box-shadow: 0 0 0 0.2rem rgba(255, 123, 0, 0.616);
}
/* стили для активной радиокнопки (при нажатии на неё) */
.custom-radio:not(:disabled):active+label::before {
  background-color: #ff7a22;
  border-color: #ff7a22;
}
/* стили для радиокнопки, находящейся в фокусе */
.custom-radio:focus+label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 123, 0, 0.616);
}
/* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
.custom-radio:focus:not(:checked)+label::before {
  border-color: #ffb980;
}
/* стили для радиокнопки, находящейся в состоянии checked */
.custom-radio:checked+label::before {
  border-color: #ff7a22;
  background-color: #ff7a22;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
/* стили для радиокнопки, находящейся в состоянии disabled */
.custom-radio:disabled+label::before {
  background-color: #e9ecef;
}
.orderingPage-shippingPayment-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.orderingPage-shippingPayment-title{
  margin: 0;
  color: #ff7a22;
}
.orderingPage-shippingPayment-shippingMethod-container{
  width: 100%;
  height: 80px;
  background-color: rgb(241, 241, 241);
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  box-sizing: border-box;
  margin-top: 20px;
}

.orderingPage-shippingPayment-shippingMethod-textWrapper{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}
.orderingPage-shippingPayment-shippingMethod-title{
  margin: 0;
}
.orderingPage-shippingPayment-shippingMethod-img{
  width: 50px;
}
.orderingPage-shippingPayment-paymentMethod-container{
  width: 100%;
  height: 100px;
  background-color: rgb(241, 241, 241);
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  box-sizing: border-box;
  margin-top: 20px;
}
.orderingPage-shippingPayment-paymentMethod-textWrapper{
  width: 70%;
  display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: flex-start;
}
.orderingPage-shippingPayment-paymentMethod-title{
  margin: 0;
}
.orderingPage-shippingPayment-shippingDetails-container{
  width: 100%;
  height: 120px;
  background-color: rgb(241, 241, 241);
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  box-sizing: border-box;
  margin-top: 20px;
}

.orderingPage-shippingCity-input{
  height: 50px;
  border:1px solid #ff7a22;
  border-radius: 15px;
  font-family: 'Montserrat';
  font-size: 15px;
  box-sizing: border-box;
  padding-left: 10px;
}
.orderingPage-shippingDepartmentNumber-input{
  height: 50px;
  width: 100%;
  border:1px solid #ff7a22;
  border-radius: 15px;
  font-family: 'Montserrat';
  font-size: 15px;
  box-sizing: border-box;
  padding-left: 10px;
}
.orderingPage-shippingCity-label{
  width: 40%;
}
.orderingPage-shippingDepartmentNumber-label{
  width: 30%;
}
.sendOrderButton-wrapper{
  width: 49%;
  height: 60px;
  margin-top: 20px;
  border-radius: 30px;
  border: none;
  padding: 0;
}
.sendOrderButton{
  width: 100%;
  height: 100%;
  background-color: #ff7a22;
  color: white;
  font-family: "Montserrat";
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  border-radius: 30px;
}
.sendOrderButton:hover{
  background-color: #f57017;
  -webkit-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.2);
}
.orderingPage-container::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(7px);
  background:rgba(255, 255, 255, 0.329) url("../images/loading.gif") center/100px no-repeat;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease 0s;
}
.orderingPage-container._sending::after{
 opacity: 1;
 visibility: visible;
}
.orderingPage-alert{
  position: fixed;
  bottom:0px;
  left:50%;
  transform:translate(-50%, -50%);
}

@media (max-width: 1224px) {
  .orderingPage-tabPanel {
    width: 80%;
    margin-bottom: 200px;
  }
}
@media (max-width: 500px) {
  .orderingPage-tabPanel {
    width: 100%;
    margin-bottom: 200px;
  }
  .orderingPage-shippingPayment-shippingDetails-container{
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }
  .orderingPage-shippingDepartmentNumber-label{
    width: auto;
    margin-top: 10px;
  }
  .orderingPage-shippingDepartmentNumber-input{
    width: 195px;
  }
  .orderingPage-shippingPayment-paymentMethod-container{
    height: auto;
  }
}