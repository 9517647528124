.price-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.price-container {
  max-width: 1200px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.price-response-container{
  width: 40%;
}
.price-text-container {
  width: 40%;
}
@media (max-width: 1224px) {
  .price-container {
    flex-direction: column-reverse;
  }
  .price-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  .price-response-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
}
@media (max-width: 760px) {
}
