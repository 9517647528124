.slider-wrapper{
    width: 100%;
    display: flex;
    justify-content:flex-end;
}
.slider-container{
    width: 85%;
    display: flex;
    justify-content: space-between;
}
.slider-button-container{
    width:15%;
    max-width: 170px;
    min-width: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.slider-button-left{
   border: none;
   height:50px;
   width: 80px;
   min-width: 50px;
   background-color: #ff7a22;
   border-radius: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.slider-button-left:active{
    background-color: #cc621b;
}
.slider-button-left:disabled{
    background-color: #a4a4a4;
}
.slider-button-right{
    border: none;
    height:50px;
    width: 80px;
    min-width: 50px;
    background-color: transparent;
    display: flex;
   align-items: center;
   justify-content: center;
   color: #ff7a22;
}
.slider-button-right:active{
  color: #cc621b;
}
.slider-button-right:disabled{
   color: #a4a4a4;
}
.slider-track-wrapper{
    width: 80%;
}
.slider-track {
    width: 100%;
    height: 270px;
    display: flex;
    align-items: center;
    overflow: hidden ;
}
.slider-item{
    min-width: 300px;
    height: 250px;
    background-color: rgb(255, 255, 255);
    transition: all 1s;
    margin-left: 25px;
    margin-right: 25px;
    border-radius: 25px;
    border: none;
    -webkit-box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
-moz-box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
    
}
.slider-item-img{
    width:100%;
    height: 75%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}
.slider-item-text{
    font-size:15px;
    font-weight:bold;
    margin-left: 10px;
    margin-top: 5px;
}


@media (max-width: 1224px) {
    .slider-container{
        width: 95%;
    }
    .slider-track-wrapper{
        width: 75%;
    }
  }
  @media (max-width: 400px) {
    .slider-wrapper{
        width: 100%;
        display: flex;
        justify-content:space-between;
    }
    .slider-button-container{
        width:10%;
        margin-right: 10px;
        max-width: 110px;
        min-width: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
  }