.backCall {
  width: 66px;
  height: 66px;
  position: fixed;
  bottom: 120px;
  right: 20px;
  border-radius: 50px;
  background-color: #ff7a22;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.155) 0px 3px 12px;
  transition: all 0.6s;
}
.backCall:hover {
  -webkit-box-shadow: 0px 0px 18px 0px #ff7a22;
  box-shadow: 0px 0px 18px 0px #ff7a22;
}
.backCall-container {
  width: auto;
  height: auto;
  padding: 15px;
  border-radius: 25px;
  background-color: rgb(255, 255, 255);
  position: fixed;
  bottom: 120px;
  right: 90px;
  z-index: 1000;
  border: #ff7a22 solid 1px;
  display: none
}
.backCall-container.show {
  display: flex;
  align-items: center;
}
.backCall-dialog-container{
    background-color: white;
    width: 500px;
    padding: 20px;
}
.backCall-buttons-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.backCall-alert{
   margin-top: 10px;
}
@media (max-width: 700px) {
    .backCall-dialog-container{
        background-color: white;
        width: auto;
        padding: 20px;
    }
}