.about-wrapper {
  margin-top: 50px;
  height: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-container {
  width: 80%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-container2 {
  width: 80%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.about-text {
  width: 50%;
}
.about-logo{
  width: 20%;
}
@media (max-width: 1224px) {
    .about-container {
        flex-direction: column;
    }
    .about-container2{
      flex-direction: column;
    }
    .about-text {
        width: 100%;
      }
      .about-logo{
        width: 30%;
      }
}
@media (max-width: 430px) {
  .about-logo{
    width: 50%;
  }
}