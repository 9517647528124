.weOffer-wrapper {
  width: 100%;
  max-width: 1340px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.weOffer-container {
  width: 80%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.weOffer-allOffers-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.weOffer-twoOffers-wrapper {
  width: 47.5%;
  display: flex;
  justify-content: space-between;
}
.weOffer-offer-container {
  perspective: 1000px;
  width: 45%;
  max-width: 220px;
  min-width:200px;
  height: 200px;
}
.weOffer-offer-container:hover .weOffer-offer-flipper,
.weOffer-offer-container.hover .weOffer-offer-flipper {
  transform: rotateY(180deg);
}

.weOffer-offer-flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  width: 100%;
  min-width:200px;
  height: 100%;
  position: relative;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(255, 112, 0, 0.2);
  -moz-box-shadow: 0px 0px 15px 1px rgba(255, 112, 0, 0.2);
  box-shadow: 0px 0px 15px 1px rgba(255, 112, 0, 0.2);
}
.weOffer-offer-firstSide,
.weOffer-offer-secondSide {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width:200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.weOffer-offer-firstSide {
  z-index: 2;
  background-color: rgb(255, 255, 255);
}
.weOffer-offer-secondSide {
  transform: rotateY(180deg);
  background-color: #ff7a22;
  color: white;
  text-align: start;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 1224px) {
  .weOffer-allOffers-container {
    flex-direction: column;
    align-items: center;
  }
  .weOffer-twoOffers-wrapper {
    width: 100%;
    max-width: 440px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}
@media (max-width: 540px) {
  .weOffer-twoOffers-wrapper{
    flex-direction: column;
    min-height: 430px;
    align-items: center;
  }
}
