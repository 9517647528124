.topScreen-container{
    width: 100%;
    height: 700px;
    background-image: url('../images/фон.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
.topScreen-center-container{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.topScreen-logo{
  width: 300px;
}
.topScreen-tagline{
    margin:0;
    text-shadow: 2px 4px 10px rgba(0,0,0,0.15);
}
.topScreen-mouse-container{
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media (max-width: 1224px) {
    .topScreen-center-container{
        width: 80%;
    }
    .topScreen-logo{
        width: 50%;
      }
  }
  @media (max-width: 769px) {
    .topScreen-center-container{
        flex-direction: column;
    }
    .topScreen-logo{
        width: 70%;
      }
      .topScreen-container{
        background-position: center;
      }
  }