.langSelect {
  font-family: "Montserrat";
  font-weight: bolder;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
}
// .css-13lcnd9-Control{
//   display: flex;
//   flex-direction: row;
//   align-content: center;
// }
.langSelect-scroll {
  font-family: "Montserrat";
  font-weight: bolder;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  animation: anims 0.5s forwards;
  @keyframes anims {
    0% {
      color: white;
    }
    100% {
      color: black;
    }
  }
}
.anims:hover{
    color:#ff7a22;
}
.langSelect-scroll:hover{
    color:#ff7a22;
}
.langSelect-option {
  font-weight: bolder;
  color: black;
}
@media (max-width: 1224px) {
  .langSelect {
    color: white;
    background-color: #ff7a22;
    border: none;
    outline: none;
  }
}
