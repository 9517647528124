.specifications-block-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.specifications-container {
  width: 80%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
}
.specifications-text-wrapper {
  width: 35%;
}
.specifications-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.specifications-items-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.specifications-items {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 25px;
}
.specifications-items-iconWrapper {
  width: 70px;
  height: 70px;
  background-color: #ff7a22;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 25px;
}
.specifications-items-line {
  width: 70px;
  background-color: #ff7a22;
  height: 2.5px;
  border-radius: 25px;
  border: none;
  margin: 0;
}
@media (max-width: 1224px) {
  .specifications-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .specifications-text-wrapper {
    width: 100%;
  }
  .specifications-wrapper {
    width: 80%;
  }
}
@media (max-width: 760px) {
  .specifications-wrapper {
    width: 100%;
  }
}
