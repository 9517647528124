html,body{
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    width: auto;
    overflow-x:hidden;
    background-color: white;
}
::-webkit-scrollbar {
    width: 6px; /* ширина для вертикального скролла */
    background-color: #292929;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ff7a22;
    border-radius: 5em;
  }
  @media (max-width: 1224px) {
    html,body{
      overflow-x: hidden;
    }
  }