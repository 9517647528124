.landingPage-wrapper{
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
}
body{
  font-size: 20px;
}
button{
  font-size: 15px;
}