.footer-wrapper {
  width: 100%;
  background-color: #292929;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
.footer-container {
  width: 80%;
  height: auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.footer-content-container {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-logoContainer {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.footer-logo1 {
  height: 100px;
}
.footer-logo2 {
  height: 100px;
}
.footer-logoStick {
  height: 80px;
  border-left: white solid 2px;
}
.footer-contactsContainer {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #3f3f3f;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 10px;
  border-radius: 35px;
}
.footer-contacts-title {
  color: #ff7a22;
  margin: 0;
}
.footer-contacts-part1 {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-contacts-address {
  max-width: 60%;
}
.footer-contacts-part2 {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-contacts-buttonWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.footer-contacts-buttonLink {
  width: auto;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ff7a22;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: "Montserrat";
  color: white;
  transition: all 0.6s;
}
.footer-contacts-buttonLink:hover {
  transition: 0.6s;
  -webkit-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
  -moz-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
  box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
}
.footer-contacts-buttonLink:active {
  background-color: #cc621b;
}
.footer-menuContainer {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.footer-nav-items {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
}
.footer-nav-item {
  display: inline;
  text-decoration: none;
  font-weight: bolder;
  text-align: center;
}
.footer-nav-item > .active {
  color: #ff7a22;
}
.footer-nav-item:hover {
  color: #ff7a22;
  cursor: pointer;
}
@media (max-width: 1224px) {
  .footer-logo1 {
    height: 60px;
  }
  .footer-logo2 {
    height: 60px;
  }
  .footer-logoStick {
    height: 40px;
    margin-left: 10px;
    border-left: white solid 2px;
  }
  .footer-logoContainer {
    max-width: 310px;
  }
  .footer-container {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .footer-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-contactsContainer {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .footer-contacts-part1 {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-contacts-part2 {
    height: 200px;
  }
}
@media (max-width: 400px) {
  .footer-container {
    width: 90%;
  }
  .footer-contacts-address {
    max-width: 80%;
  }
  .footer-contacts-buttonLink {
    padding-left: 0px;
    padding-right: 0px;
  }
  .footer-logoContainer {
    width: 80%;
  }
}
