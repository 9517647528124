.orderSuccessPage-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.orderSuccessPage-container {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.orderSuccessPage-textContainer{
    width: max-content;
    height: max-content;
    background-color: rgb(241, 241, 241);
    padding: 15px;
    border-radius:20px;
}
.orderSuccessPage-backButton{
    text-decoration: none;
    color: #ff7a22;
    font-weight: bold;
    margin-top: 20px;
    display:flex;
    align-items: center;
    background-color: transparent;
}
.orderSuccessPage-backButton:hover{
    color: #d3661e;
}
@media (max-width: 350px) {
    .orderSuccessPage-textContainer{
        width: min-content;
    }
  }