.mobileSlider-wrapper {
  width: 90%;
}
.mobileSlider-slide-wrapper {
  width: 35%;
}
.mobileSlider-slide {
  width: 90%;
  min-width: 220px;
  max-width: 270px;
  height: 200px;
  background-color: rgb(255, 255, 255);
  transition: all 1s;
  border-radius: 25px;
  border: none;
  -webkit-box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
}
.mobileSlider-item-img {
  width: 100%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.mobileSlider-item-text {
  max-width: 200px;
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 5px;
}