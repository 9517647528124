.header-wrapper {
  position: fixed;
  top: 0;
  z-index: 1000;
  color: rgb(255, 255, 255);
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  animation: ani1 0.5s forwards;
  @keyframes ani1 {
    0% {
      height: 70px;
      background-color: rgba(255, 255, 255, 0.212);
      color: black;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
    100% {
      height: 100px;
      background-color: transparent;
      color: white;
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
    }
  }
}

.header-scroll-wrapper {
  position: fixed;
  top: 0;
  z-index: 1000;
  color: white;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: none;
  animation: ani2 0.5s forwards;
  @keyframes ani2 {
    0% {
      height: 100px;
      background-color: transparent;
      color: white;
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
    }
    100% {
      height: 70px;
      background-color: rgba(255, 255, 255, 0.212);
      color: black;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      -webkit-box-shadow: -1px 6px 22px 1px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: -1px 6px 22px 1px rgba(34, 60, 80, 0.2);
      box-shadow: -1px 6px 22px 1px rgba(34, 60, 80, 0.2);
    }
  }
}

.header-container {
  width: 80%;
  max-width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header-logo-container {
  width: 130px;
  height: 100%;
  background-color: #ff7a22;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer ;
}
.header-logo {
  width: 85%;
  animation: ani4 0.5s forwards;
  @keyframes ani4 {
    0% {
      width: 65%;
    }
    100% {
      width: 85%;
    }
  }
}
.header-scroll-logo {
  width: 85%;
  animation: ani3 0.5s forwards;
  @keyframes ani3 {
    0% {
      width: 85%;
    }
    100% {
      width: 65%;
    }
  }
}
.header-contacts-buttonLink{
  width:auto;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background-color:#ff7a22;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: 'Montserrat';
  color: white;
  transition:all 0.6s ;
}
.header-contacts-buttonLink:hover{
  transition: 0.6s ;
  -webkit-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
-moz-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
}
.header-contacts-buttonLink:active{
  background-color:#cc621b;
}
.mobileHeader-logo {
  height: 50px;
  width: 80px;
}

.header-navbar {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}
.header-nav-items {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  font-weight: bolder;
  font-size: 15px;
  justify-content: space-between;
}
.header-nav-item {
  display: inline;
  text-decoration: none;
  text-align: center;
}
.header-nav-item > .active {
  color: #ff7a22;
}
.header-nav-item:hover {
  color: #ff7a22;
  cursor: pointer;
}
.mobileHeader-wrapper {
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #ff7a22;
  align-items: center;
}
.mobileHeader {
  position: relative;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.-scroll{
  box-shadow: -1px 0px 11px -2px rgba(0,0,0,0.75);
}
.iconButton-header{
  background-color:#ff7a22;
  box-shadow: -1px 0px 11px -20px rgba(0,0,0,0.75);
}
@media (max-width: 1224px){
  .header-contacts-buttonLink{
    width: 80%;
  }
}