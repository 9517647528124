.priceCounter-container {
  height: 250px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ff7a22;
  border-radius: 25px;
  max-width: 460px;
  box-sizing: border-box;
  padding: 15px;
}
.priceCounter-form-container{
  position: relative;
}
.priceCounter-form-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background: rgb(255, 255, 255) url("../images/loading.gif") center/100px no-repeat;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease 0s;
}
.priceCounter-form-container._sendingFile::after {
  opacity: 1;
  visibility: visible;
}
.priceCounter-inputWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.priceCounter-input {
  width: 35%;
  height: 50px;
  border: 1px solid #ff7a22;
  border-radius: 15px;
  font-family: "Montserrat";
  text-align: center;
}
.priceCounter-priseWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  margin-top: 25px;
  color: #ff7a22;
  align-items: center;
}
.priceCounter-button {
  height: 50px;
  width: 150px;
  border: none;
  border-radius: 35px;
  background-color: #ff7a22;
  font-family: "Montserrat";
  font-weight: bold;
  color: white;
}
.priceCounter-button:hover {
  transition: 0.6s;
  -webkit-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
  -moz-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
  box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
}

.priceCounter-button:active {
  background-color: #cc621b;
}
.priceCounter-button:disabled{
  background-color: #a7a7a7;
}
.priceCounter-text {
  font-size: 14px;
  color: #ff7a22;
}
