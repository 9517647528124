.partner-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.partner-container {
  width: 80%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.partner-textContainer {
  width: 35%;
}
.partner-text {
  width: 70%;
}
.partner-formContainer {
  width: 50%;
  border: 1px solid #ff7a22;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 20px;
}
.partner-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
    width: 100%;
}
.partner-form::after{
  content: "";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
backdrop-filter: blur(2px);
background:rgb(255, 255, 255) url("../images/loading.gif") center/100px no-repeat;
opacity: 0;
visibility: hidden;
transition: all 0.5s ease 0s;
}
.partner-form._sendingFile::after{
  opacity: 1;
visibility: visible;
}
.partner-inputLabel {
  margin-bottom: 20px;
}
.partner-input {
  height: 40px;
  border: 1px solid #ff7a22;
  border-radius: 15px;
  font-family: "Montserrat";
  font-size: 15px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
}
.info {
  height: 200px;
  padding: 10px;
  overflow: auto;
  resize: none;
}
.partner-formButton {
  height: 50px;
  width: 150px;
  border: none;
  border-radius: 35px;
  background-color: #ff7a22;
  font-family: "Montserrat";
  font-weight: bold;
  color: white;
}
.partner-formButton:hover {
  transition: 0.6s;
  -webkit-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
  -moz-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
  box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
}
.partner-formButton:disabled{
  background-color: #a7a7a7;
}
.partner-input-error{
  margin: 0;
  margin-top: 3px;
  font-size: 12px;
  color: #ff7a22;
}
@media (max-width: 1224px) {
    .partner-container{
        flex-direction: column;
    }
    .partner-textContainer{
        width: 100%;
        margin-bottom: 30px;
    }
    .partner-formContainer{
        width: 100%;
    }
    .partner-text {
        width: 100%;
      }
}
