.orderingButton{
  margin-top: 50px;
  width: 300px;
  height: 50px;
  border: solid 1px #ff7a22;
  border-radius: 25px;
  background-color: #ff7a22;
  color: #ffffff;
  font-family: 'Montserrat';
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  transition: 0.6s;

}
.orderingButton:hover{
    transition: 0.6s ;
    -webkit-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
-moz-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
}
.orderingButton:active{
  background-color:#cc621b;
}
.orderingButton-iconWrappe{
    height: 100%;
    
}