.inputFile-wrapper {
  width: 100%;
  position: relative;
  margin: 15px 0;
  text-align: center;
}
.field__file {
width: 100%;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.field__file-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
   
  align-items: center;
}

.field__file-fake {
  height: 60px;
  width: 45%;
  display: flex;
  color: rgb(122, 122, 122);
  background-color: #fff;
  align-items: center;
  padding: 0 15px;
  border: 1px solid #ff7a22;
  border-radius: 15px;
}

.field__file-button {
  width: 35%;
  height: 60px;
  background: #ff7a22;
  color: #fff;
  font-weight: bold;

  display: flex;

  align-items: center;

  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
}
.field__file-button:hover{
    transition: 0.6s ;
    -webkit-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
-moz-box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
box-shadow: 1px 2px 18px 17px rgba(238, 95, 0, 0.2);
}

.field__file-button:active{
    background-color:#cc621b;
}